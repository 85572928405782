html, body {
  height:100vh;
}

body{
  overflow: auto;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}
